<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0" v-if="serverData">
          <!--          <div class="col-12 col-md-6 col-md-4 h-75px" v-if="!isClient">-->
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.title"
              label="Title"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.receiver_type"
              label="Receiver type"
              item-text="label"
              item-value="value"
              :items="serverData.receiver_types"
              clearable
              outlined
              dense
              :error-messages="receiver_typeErrors"
              @input="$v.formData.receiver_type.$touch()"
              @blur="$v.formData.receiver_type.$touch()"
            />
          </div>

          <div
            class="col-12 h-75px"
            v-if="
              formData.receiver_type !== 'end_customer' &&
              formData.receiver_type !== 'self'
            "
          >
            <v-autocomplete
              v-model="formData.receiver_values"
              :label="receiverDetails.label"
              :items="receiverDetails.list"
              item-text="text"
              item-value="index"
              multiple
              small-chips
              deletable-chips
              clearable
              outlined
              dense
              :error-messages="receiver_valuesErrors"
              @input="$v.formData.receiver_values.$touch()"
              @blur="$v.formData.receiver_values.$touch()"
            />
            <!--            :multiple="receiverDetails.isMultiple"-->
            <!--            :small-chips="receiverDetails.isMultiple"-->
            <!--            :deletable-chips="receiverDetails.isMultiple"-->
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_type_id"
              label="Action type"
              :items="receiverDetails?.action_types"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
              :error-messages="action_type_idErrors"
              @input="$v.formData.action_type_id.$touch()"
              @blur="$v.formData.action_type_id.$touch()"
            />
          </div>

          <div
            v-if="
              formData.action_type_id === 2 || formData.action_type_id === 3
            "
            class="col-12 col-md-6 h-75px"
          >
            <v-autocomplete
              v-model="formData.action_types"
              :label="actionTypeDetails.label"
              :items="actionTypeDetails.list"
              item-value="code"
              item-text="text"
              multiple
              small-chips
              deletable-chips
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_id"
              :label="actionNotificationDetails.label"
              :items="actionNotificationDetails.list"
              item-value="code"
              item-text="text"
              :multiple="actionNotificationDetails.isMultiple"
              :small-chips="actionNotificationDetails.isMultiple"
              :deletable-chips="actionNotificationDetails.isMultiple"
              clearable
              outlined
              dense
              :error-messages="action_idErrors"
              @input="$v.formData.action_id.$touch()"
              @blur="$v.formData.action_id.$touch()"
            />
          </div>

          <template v-if="selectedActionDetails?.timing">
            <div
              class="col-12 col-md-6 h-75px"
              :class="{ 'col-md-12': formData.schedule_type === 2 }"
            >
              <v-autocomplete
                v-model="formData.schedule_type"
                label="Schedule Type"
                :items="serverData.schedule_types"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
                hide-selected
                :error-messages="schedule_typeErrors"
                @blur="$v.formData.schedule_type.$touch()"
              />
            </div>

            <div
              class="col-12 col-md-6 h-75px"
              v-if="formData.schedule_type === 2"
            >
              <v-autocomplete
                v-model="formData.days"
                label="Day"
                item-text="text"
                item-value="index"
                :items="serverData.days"
                multiple
                small-chips
                deletable-chips
                clearable
                outlined
                dense
                :error-messages="daysErrors"
                @input="$v.formData.days.$touch()"
                @blur="$v.formData.days.$touch()"
              />
            </div>

            <div class="col-12 col-md-6 h-75px">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-top="200"
                :return-value.sync="formData.at"
                transition="scale-transition"
                offset-x
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.at"
                    label="Time"
                    prepend-inner-icon="mdi-clock-outline"
                    readonly
                    outlined
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="atErrors"
                    @input="$v.formData.template_id.$touch()"
                  />
                </template>
                <v-time-picker
                  format="24hr"
                  v-if="dateMenu"
                  v-model="formData.at"
                  full-width
                  @click:minute="$refs.dateMenu.save(formData.at)"
                ></v-time-picker>
              </v-menu>
            </div>
          </template>

          <div
            v-if="selectedActionDetails?.template"
            class="col-12 h-75px"
            :class="{
              'col-md-6':
                formData.action_type_id === 2 || formData.action_type_id === 3,
            }"
          >
            <v-autocomplete
              v-model="formData.template_id"
              label="Template"
              item-text="text"
              item-value="index"
              :items="serverData.templates"
              clearable
              outlined
              dense
              :error-messages="template_idErrors"
              @input="$v.formData.template_id.$touch()"
              @blur="$v.formData.template_id.$touch()"
            />
          </div>
          <!--          </template>-->
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_ACTIONS_DIALOG_SERVER_DATA } from "@/core/services/store/notifications.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import Swal from "sweetalert2";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      title: { required },
      receiver_type: { required },
      receiver_values: {
        required: requiredIf(function () {
          // let isRequired = true;
          // if (this.isClient && this.formData.receiver_type === "end_customer") {
          //   isRequired = false;
          // }
          return (
            this.formData.receiver_type !== "end_customer" &&
            this.formData.receiver_type !== "self"
          );
        }),
      },
      action_type_id: { required },
      action_id: { required },
      // action_types: {
      //   required: requiredIf(function () {
      //     return (
      //       this.formData.action_type_id === 2 ||
      //       this.formData.action_type_id === 3
      //     );
      //   }),
      // },
      schedule_type: {
        required: requiredIf(function () {
          return this.formData.action_type_id === 1;
        }),
      },
      days: {
        required: requiredIf(function () {
          return this.formData.action_type_id === 1;
        }),
      },
      at: {
        required: requiredIf(function () {
          return this.formData.action_type_id === 1;
        }),
      },
      template_id: {
        required: requiredIf(function () {
          return this.formData.action_type_id !== 1;
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    dateMenu: null,
    formData: {
      title: null,
      receiver_type: null,
      receiver_values: null, // type is Array for multiple select and integer for single
      action_type_id: null,
      action_types: [],
      action_id: null, // type is Array for multiple select and integer for single
      schedule_type: null, // Weekly, Daily, if selected daily show days input
      days: [1, 2, 3, 4, 5, 6, 7],
      at: "",
      template_id: null,
    },
    disableWatchers: true,
    isForEdit: false,
    receiverDetails: {
      label: "Receiver",
      list: [],
      isMultiple: true,
      action_types: [],
    },
    actionTypeDetails: {
      label: "Action types",
      list: [],
    },
    actionNotificationDetails: {
      label: "Action",
      list: [],
      isMultiple: false,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/settings/notifications/edit", sendData)
          .then(({ data }) => {
            this.isForEdit = true;
            if (!this.serverData) {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
            }
            this.formData = {
              // id: sendData.id,
              ...data.notification,
              action_type_id: data.notification.action_id,
              action_types: data.notification.action_types,
              action_id: [...data.notification.values],
            };

            this.disableWatchers = false;
            this.dialog = true;
          })
          .catch(() => {
            this.dialog = false;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        if (!this.serverData) {
          this.pageLoader(true);
          ApiService.post("/settings/notifications/create")
            .then(({ data }) => {
              this.isForEdit = false;
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        this.disableWatchers = false;
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        // this.dialog = !this.dialog;
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");

        // if (fieldName === "at") {
        //   const regWithSeconds = RegExp(
        //     // eslint-disable-next-line
        //     /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/
        //   );
        //   if (!regWithSeconds.test(this.formData.at)) {
        //     errors.push("Time have to be in 24h HH:MM or HH:MM:SS format");
        //   }
        // }
      }
      return errors;
    },
    submitForm() {
      if (!this.selectedReceiverDetails) {
        Swal.fire({
          title: "Warning",
          text: "Please select receiver details",
          icon: "error",
          showConfirmButton: true,
          timer: 10000,
        });
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.$store.getters.isClient) delete this.formData.customer_id;

      const payload = {
        title: this.formData.title,
        receiver_type: this.formData.receiver_type,
        receiver_values:
          this.formData.receiver_type !== "end_customer" &&
          this.formData.receiver_type !== "self"
            ? this.formData.receiver_values
            : [],
        action_id: this.formData.action_type_id,
        values: this.formData.action_id,
        // schedule_type: this.formData.schedule_type,
        // days: this.formData.days,
        // at: this.formData.at,
        // template_id: this.formData.template_id,
      };

      if (this.formData.action_type_id === 1) {
        payload.schedule_type = this.formData.schedule_type;
        payload.at = this.formData.at;

        if (this.formData.schedule_type === 2) {
          payload.days = this.formData.days;
        }
      } else {
        payload.template_id = this.formData.template_id;
        payload.action_types = this.formData.action_types;
      }

      // if (this.formData.action_type_id === 1) {
      //   payload.values = this.formData.action_id;
      // }
      //   if (!this.receiverDetails.isMultiple) {
      //     payload.receiver_values = [this.formData.receiver_values];
      //   }

      if (!this.actionNotificationDetails.isMultiple) {
        payload.values = [this.formData.action_id];
      }
      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        payload.id = this.formData.id;
      }

      this.pageLoader(true);
      ApiService.post(`/settings/notifications/${actionUrl}`, payload)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        title: null,
        receiver_type: null,
        receiver_values: null,
        action_type_id: null,
        action_types: null,
        action_id: null,
        schedule_type: null,
        days: [1, 2, 3, 4, 5, 6, 7],
        at: "",
        template_id: null,
      };
      this.disableWatchers = true;
      this.isForEdit = false;
      this.receiverDetails = {
        label: "Receiver",
        list: [],
        isMultiple: true,
        action_types: [],
      };
      this.actionTypeDetails = {
        label: "Action types",
        list: [],
      };
      this.actionNotificationDetails = {
        label: "Action",
        list: [],
        isMultiple: false,
      };
    },
  },
  computed: {
    // receivers: function () {
    //   if (this.isClient) {
    //     return [
    //       { index: "customer_accounts", text: "Accounts" },
    //       { index: "end_customer", text: "End customer" },
    //     ];
    //   } else {
    //     return [
    //       { index: "users", text: "IQ users" },
    //       { index: "customer_accounts", text: "Client / Client accounts" },
    //       { index: "end_customer", text: "End customer" },
    //     ];
    //   }
    // },
    serverData: function () {
      return this.$store.getters.getNOTIFICATIONSActionsDialogServerData;
    },
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    titleErrors: function () {
      return this.handleFormValidation("title");
    },
    receiver_typeErrors: function () {
      return this.handleFormValidation("receiver_type");
    },
    receiver_valuesErrors: function () {
      return this.handleFormValidation("receiver_values");
    },
    action_type_idErrors: function () {
      return this.handleFormValidation("action_type_id");
    },
    // action_typesErrors: function () {
    //   return this.handleFormValidation("action_types");
    // },
    action_idErrors: function () {
      return this.handleFormValidation("action_id");
    },
    schedule_typeErrors: function () {
      return this.handleFormValidation("schedule_type");
    },
    daysErrors: function () {
      return this.handleFormValidation("days");
    },
    atErrors: function () {
      return this.handleFormValidation("at");
    },
    template_idErrors: function () {
      return this.handleFormValidation("template_id");
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    selectedReceiverType() {
      return this.formData.receiver_type;
    },
    selectedReceiverDetails() {
      return this.serverData.receiver_types.find(
        (item) => item.value === this.formData.receiver_type
      );
    },
    selectedActionType() {
      return this.formData.action_type_id;
    },
    selectedActionDetails() {
      return this.selectedReceiverDetails?.action_types?.actions.find(
        (item) => item.index === this.formData.action_type_id
      );
    },
  },
  watch: {
    selectedReceiverType: function (newValue, oldValue) {
      // console.log("newValue selectedReceiverType", newValue);
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          this.receiverDetails.label = this.selectedReceiverDetails.label;
          this.receiverDetails.list = this.selectedReceiverDetails.receivers;

          this.receiverDetails.action_types =
            this.selectedReceiverDetails.action_types.actions;

          if (!this.isForEdit) {
            this.formData.receiver_values = [];
          }
        }
      } else {
        this.receiverDetails = {
          label: "Receiver",
          list: [],
          isMultiple: true,
          action_types: [],
        };
      }
    },
    selectedActionType: function (newValue, oldValue) {
      // console.log("newValue selectedActionType", newValue);
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          this.actionNotificationDetails.label =
            this.selectedActionDetails.text;
          if (newValue === 1) {
            this.formData.template_id = null;
            this.actionNotificationDetails.isMultiple = false;
            this.actionNotificationDetails.list =
              this.selectedReceiverDetails?.action_types.reports;

            // console.log("this.isForEdit", this.isForEdit);
            if (!this.isForEdit) this.formData.action_id = null;
            else if (this.formData.action_id) {
              this.formData.action_id = this.formData.action_id[0];
            }
          } else {
            this.formData.days = [1, 2, 3, 4, 5, 6, 7];
            this.formData.at = "";
            this.actionNotificationDetails.isMultiple = true;
            if (!this.isForEdit) this.formData.action_id = [];

            if (newValue === 2) {
              this.actionTypeDetails.label = "Order Type";
              this.actionTypeDetails.list =
                this.selectedReceiverDetails?.action_types.order_types;
              this.actionNotificationDetails.list =
                this.selectedReceiverDetails?.action_types.order_statues;
            } else if (newValue === 3) {
              this.actionTypeDetails.label = "ASN Type";
              this.actionTypeDetails.list =
                this.selectedReceiverDetails?.action_types.asn_types;
              this.actionNotificationDetails.list =
                this.selectedReceiverDetails?.action_types.asn_statuses;
            }
          }

          this.isForEdit = false;
        }
      } else {
        this.actionTypeDetails = {
          label: "Action types",
          list: [],
        };
        this.actionNotificationDetails = {
          label: "Action",
          list: [],
          isMultiple: false,
        };
      }
    },
  },
};
</script>
